<template>
  <div>
    <h2>ラベル管理</h2>
    <pv-data-table ref="dt" :value="labels" :rows="10" :totalRecords="totalRecords"
      :loading="loading" paginator lazy @page="loadClientLabels" @sort="loadClientLabels">
      <template #header>
        <div class="flex justify-content-between">
          <div class="flex justify-content-start">
            <pv-input class="ml-1" label="名前" v-model="cond.name" float icon="search" />
            <pv-dropdown class="ml-2" v-model="cond.char_type" :options="CharTypes" optionLabel="label" optionValue="value" placeholder="入力種別" />
          </div>
          <div class="flex justify-content-end">
            <pv-button label="登録" icon="pi pi-plus" @click="labelDialog = true" />
          </div>
        </div>
      </template>
      <pv-column field="id" header="ID" sortable />
      <pv-column field="name" header="名前" sortable />
      <pv-column field="type" header="タイプ" sortable>
        <template #body="{data}">
          {{ LabelType[data.type] }}
        </template>
      </pv-column>
      <pv-column field="char_type" header="入力制限" sortable>
        <template #body="{data}">
          {{ data.char_type.map(t => CharType[t]).join(' / ') }}
        </template>
      </pv-column>
      <pv-column field="char_width" header="文字制限" sortable>
        <template #body="{data}">
          {{ data.char_width.map(w => CharWidth[w]).join(' / ') }}
        </template>
      </pv-column>
      <pv-column field="created_at" header="登録日時" sortable>
        <template #body="{data}">
          {{ dayjs(data.created).format('YYYY/MM/DD HH:mm') }}
        </template>
      </pv-column>
      <pv-column field="id">
        <template #body="{data}">
            <pv-button class="ml-2 p-button-text" icon="pi pi-pencil" @click="editClientLabel(data)" />
            <pv-button class="ml-2 p-button-danger p-button-text" icon="pi pi-trash" @click="removeClientLabel(data)" />
        </template>
      </pv-column>
    </pv-data-table>
  </div>

  <pv-dialog v-model:visible="labelDialog" :header="label.id ? 'ラベル編集' : 'ラベル登録'" modal :closable="false" :style="{width: '420px'}">
    <form @submit.prevent="updateClientLabel">
      <div class="formgrid grid">
        <label class="col-4">タイプ</label>
        <div class="col-8 field">
          <pv-radio label="ID" value="id" v-model="label.type" />
          <div class=" formgroup-inline">
            <pv-radio label="属性" value="attr" v-model="label.type" />
            <template v-if="label.type == 'attr'">
              <pv-checkbox label="必須にする" value="on" v-model="label.required" />
            </template>
          </div>
        </div>
      </div>
      <div class="formgrid grid">
        <label class="col-4 input-label">名前</label>
        <div class="col-8 field">
          <input-text type="text" v-model="label.name" class="w-full" required />
        </div>
      </div>
      <div class="formgrid grid">
        <label class="col-4">入力制限</label>
        <div class="col-8 field formgroup-inline">
          <pv-checkbox v-for="[k, v] in Object.entries(CharType)" :key="k" :label="v" :value="k" v-model="label.char_type" />
        </div>
      </div>
      <div class="formgrid grid">
        <label class="col-4">文字制限</label>
        <div class="col-8 field formgroup-inline">
          <pv-checkbox v-for="[k, v] in Object.entries(CharWidth)" :key="k" :label="v" :value="k" v-model="label.char_width" />
        </div>
      </div>
      <div class="formgrid grid">
        <label class="col-4 input-label">文字数制限</label>
        <div class="col-8 field p-inputgroup">
          <input-text type="number" v-model="label.min_length" />
          <span class="p-inputgroup-addon">~</span>
          <input-text type="number" v-model="label.max_length" />
        </div>
      </div>

      <input v-show="false" ref="formSubmit" type="submit" />
    </form>

    <template #footer>
      <pv-button label="取消" class="p-button-outlined" @click="cancel" />
      <pv-button :label="label.id ? '更新' : '登録'" @click="formSubmit.click()" />
    </template>
  </pv-dialog>

  <pv-dialog header="ラベル削除" v-model:visible="confirmDelete" modal :closable="false">
    <div class="confirmation-content">
      <span><strong>{{ delClientLabel.name }}</strong>を削除します。<br />よろしいですか？</span>
    </div>
    <template #footer>
      <pv-button label="取消" class="p-button-outlined" @click="confirmDelete = false" />
      <pv-button label="削除" @click="removeClientLabel(delClientLabel, true)" />
    </template>
  </pv-dialog>
</template>

<style scoped>
::v-deep(.p-dropdown) {
  max-height: 2.5em;
}
::v-deep(.field > .p-float-label > label) {
  font-weight: normal!important;
}
::v-deep(.p-column-header-content) {
  position: relative;
}
::v-deep(th:last-of-type > .p-column-header-content) {
  float: right;
}
::v-deep(.p-column-header-content > .p-sortable-column-icon) {
  position: absolute;
  left: -27px;
}

.flex.justify-content-end {
  max-height: 2.5em;
}
</style>

<script>
import { ref, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useToast } from "primevue/usetoast";
import { getClientLabels, postClientLabel, deleteClientLabel } from "@/common/api";
import { LabelType, CharType, CharWidth } from "@/common/master";

export default {
  layout: "app",
  setup() {
    const store = useStore();
    const dt = ref();
    const loading = ref(false);
    const totalRecords = ref(0);
    const labels = ref([]);
    const labelParams = ref({});

    onMounted(async () => {
      await loadClientLabels({
        first: 0,
        rows: dt.value.rows,
        sortField: null,
        sortOrder: null,
      });
    });

    async function loadClientLabels(params) {
      if (!store.getters.isLogin) return;
      loading.value = true;
      params.name = cond.value.name;
      params.char_type = cond.value.char_type;
      labelParams.value = params;
      let data = await getClientLabels(params);

      labels.value = data.labels;
      totalRecords.value = data.total;
      loading.value = false;
    }

    const cond = ref({ name: "", char_type: "" });

    watch(() => cond.value, async () => {
      await loadClientLabels(labelParams.value);
    }, { deep: true });

    const labelDialog = ref(false);
    const label = ref({type: "id", char_type: ["all"], char_width: ["half", "full"]});
    const error = ref({ email: "", password: "" });
    const formSubmit = ref();

    watch(() => labelDialog.value, (dialog) => {
      if (!dialog) {
        label.value = {};
      }
    });

    const toast = useToast();

    const CharTypes = computed(() => {
      let val = [{ label: "入力種別", value: "" }];
      Object.entries(CharType).forEach(([value, label]) => {
        val.push({ label, value });
      });
      return val;
    });

    const CharWidths = computed(() => {
      let val = [];
      Object.entries(CharWidth).forEach(([value, label]) => {
        val.push({ label, value });
      });
      return val;
    });

    watch(() => label.value.char_type, (nl, ol) => {
      if (!nl || !ol) return;
      if (nl.includes("all") && !ol.includes("all") && nl.length > 1) {
        label.value.char_type = ["all"];
      } else if (nl.includes("all") && ol.includes("all") && nl.length > 1) {
        label.value.char_type = label.value.char_type.filter(ct => ct !== 'all');
      }
    });

    async function updateClientLabel() {
      let l = { ...label.value };

      if (l.char_type.length == 0) {
        alert("入力種別は最低1つ選択して下さい");
        return;
      }
      if (l.char_width.length == 0) {
        alert("文字幅は最低1つ選択して下さい");
        return;
      }
      if (l.char_type.includes("hira") && !l.char_width.includes("full")) {
        alert("入力種別にひらがなが含まれていますが、全角が許可されていません");
        return;
      }

      l.required = (l.required && l.required.length > 0);
      let data = await postClientLabel(l);
      if (data.error) {
        return;
      }

      if (label.value.id) {
        toast.add({ severity: "success", summary: "ラベル更新", detail: "ラベルを更新しました", life: 3000 });
      } else {
        toast.add({ severity: "success", summary: "ラベル登録", detail: "ラベルを登録しました", life: 3000 });
      }

      labelDialog.value = false;

      await loadClientLabels(labelParams.value);
    }

    function editClientLabel(l) {
      label.value = {...l};
      if (label.value.required) label.value.required = ["on"];
      labelDialog.value = true;
    }

    function cancel() {
      label.value = {};
      error.value = {};
      labelDialog.value = false;
    }

    const confirmDelete = ref(false);
    const delClientLabel = ref({});

    async function removeClientLabel(l, force=false) {
      if (!force) {
        delClientLabel.value = l;
        confirmDelete.value = true;
        return;
      }

      await deleteClientLabel(l.id);
      confirmDelete.value = false;
      await loadClientLabels(labelParams.value);
    }

    return {
      dt, loading, totalRecords, labels, cond,
      labelDialog, label, formSubmit, error, cancel,
      confirmDelete, delClientLabel, removeClientLabel,
      loadClientLabels, updateClientLabel, editClientLabel,
      LabelType, CharType, CharWidth, CharTypes, CharWidths,
    };
  }
}
</script>
